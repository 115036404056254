<template>
  <div class="container px-2 mx-auto dark:bg-gray-900">
    <div class="flex flex-row space-x-6 rounded-t-xl">
      <div class="flex items-center justify-start">
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
        >
          Detalles de Asiento N°
        </h2>
        <div
          class="ml-1 border-solid border-2 rounded-l-md rounded-r-md -mt-1 flex items-center"
        >
          <span
            class="flex items-center mt-1 justify-center px-2 text-xl font-bold"
            >{{ objAccount.accounting_entry_code }}
          </span>
          <button
            title="Comentario de Modificación"
            v-if="objAccount.id > 0"
            v-on:click="saveAccountingEntry(objAccount.id)"
            class="inline-flex items-center px-2 py-1 text-sm text-white bg-gradient-to-t from-blue-600 to-blue-500 shadow sm:text-base hover:bg-blue-400 hover:bg-gradient-to-t hover:from-blue-500 hover:to-blue-600"
          >
            <i class="icon-edit" aria-hidden="true"></i>
          </button>
          <button
            title="Eliminar Asiento"
            v-if="objAccount.id > 0"
            v-on:click="DeleteAccountingEntry(objAccount.id)"
            class="inline-flex items-center px-2 py-1 text-sm text-white bg-gradient-to-t from-red-600 to-red-500 shadow sm:text-base rounded-r-md hover:bg-gradient-to-t hover:from-red-500 hover:to-red-600"
          >
            <i class="icon-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <nav class="sticky container mx-auto w-full top-0">
      <div
        class="flex flex-row flex-shrink-0 items-center justify-start shrink-0 text-sm font-bold uppercase rounded-t-lg bg-gradient-to-t from-blue-600 to-blue-500 text-white"
      >
        <div class="flex items-center pl-3 w-32 h-10 px-1">
          <span>Fecha</span>
        </div>
        <div class="flex items-center w-80 h-10 px-4">
          <span>Cuenta</span>
        </div>
        <div class="flex items-center flex-1 w- h-10 px-1">
          <span>Descripcion</span>
        </div>
        <div class="flex items-center flex-1 w-52 h-10 px-1">
          <span>Referencia</span>
        </div>
        <div class="flex items-center justify-start text-left w-28 h-10 px-1">
          <span>Debito</span>
        </div>
        <div class="flex items-center justify-start text-left w-28 h-10 px-1">
          <span>Credito</span>
        </div>
        <div class="flex items-center justify-center w-16 h-10 px-1">
          <span>Opc</span>
        </div>
      </div>
    </nav>

    <div class="overflow-hidden border-l-2 border-r-2">
      <div
        class="flex flex-shrink-0 justify-start text-sm hover:bg-blue-100 duration-150"
        v-for="(item, index) in objAccount.account_transactions"
        :key="index"
      >
        <div
          class="flex items-center overflow-y-auto flex-wrap w-32 h-6 px-1 border-b border-gray-300"
        >
          <div v-if="editMode === index" class="flex block-inline overflow-x-hidden overflow-y-hidden">
            <input
              type="date"
              v-model="item.transactionDate"
              class="border-2 w-40 border-gray-300 rounded"
            />
          </div>
          <div v-else class="">
            {{ formatearFecha(item.transactionDate) }}
          </div>
        </div>
        <div
          class="flex items-center text-xs text-left flex-wrap overflow-y-auto w-80 h-6 px-1 border-b border-gray-300"
          v-bind:title="item.account.accountCode + ' - ' + item.account.accountName"
        >
          <span>
            <router-link
              :to="{
                name: 'ledgerAccounts',
                params: { url: 'entrynumber', account_id: item.account_id },
              }"
              class="-py-1 px-2 border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200"
            >
              {{ item.account.accountCode }} -
              {{ item.account.accountName }}
            </router-link>
          </span>
        </div>
        <div
          class="flex items-center text-xs text-left flex-1 w-52 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
          v-bind:title="item.description"
        >
          <input
            v-if="editMode === index"
            type="text"
            v-model="item.description"
            class="border-2 px-1 border-gray-300 rounded-r-md md:w-50 overflow-x-hidden"
          />
          <span v-else> {{ item.description }} </span>
        </div>
        <div
          class="flex items-center text-xs text-left flex-1 w-52 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
          v-bind:title="item.reference_type + ' : ' + item.reference + ' - ' + item.auxiliary"
        >
          <div v-if="editMode === index" class="flex block-inline overflow-x-hidden overflow-y-hidden">
            <select
              v-model="item.reference_type"
              class="border-2 border-gray-300 rounded"
            >
              <option value=""></option>
              <option value="FAC">Factura</option>
              <option value="CT">Contrato</option>
              <option value="DP">Deposito</option>
              <option value="CH">Cheque</option>
              <option value="TRA">Tranferencia</option>
              <option value="NC">Nota Credito</option>
              <option value="ND">Nota Debito</option>
              <option value="TC">Tarjeta Credito</option>
              <option value="TD">Tarjeta Debito</option>
              <option value="ZE">Zelle</option>
              <option value="REI">Reintegro</option>
              <option value="EFE">Efectivo</option>
              <option value="PRES">Prestamo</option>
              <option value="WTra">Wire Transfer</option>
            </select>
            <input
              type="text"
              v-model="item.reference"
              class="border-2 border-gray-300 rounded w-full"
              placeholder="Referencia"
            />
            <input
              type="text"
              v-model="item.auxiliary"
              class="border-2 border-gray-300 rounded w-full"
              placeholder="Aux"
            />
          </div>
          <span v-else
            >{{ item.reference_type }}: {{ item.reference }} -
            {{ item.auxiliary }}
          </span>
        </div>
        <div
          class="flex items-center justify-start text-left text-xs w-28 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <div v-if="editMode === index" class="flex block-inline overflow-x-hidden overflow-y-hidden">
            <input
              type="text"
              v-model="item.debit"
              class="border-2 px-2 border-gray-300 rounded-r-md w-28"
            />
          </div>
          <div v-else>
            {{ item.debit > 0 ? formatNumber(item.debit, ",", "$", true) : "" }}
          </div>
        </div>
        <div
          class="flex items-center justify-start text-xs w-28 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <div v-if="editMode === index" class="flex block-inline overflow-x-hidden overflow-y-hidden">
            <input
              type="text"
              v-model="item.credit"
              class="border-2 px-2 border-gray-300 rounded-r-md w-28"
            />
          </div>
          <div v-else>
            {{
              item.credit > 0 ? formatNumber(item.credit, ",", "$", true) : ""
            }}
          </div>
        </div>
        <div
          class="flex items-center justify-center w-16 h-6 px-1 border-b border-gray-300"
        >
          <button
            v-if="editMode === index"
            v-on:click="isHidden = !isHidden"
            @click="updateRowTransactionSave(index, item)"
            title="Editar Fila"
            class="py-1 px-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-ok" aria-hidden="true"></i>
          </button>
          <button
            v-if="editMode === index"
            v-on:click="isHidden = !isHidden"
            @click="delRowTransactionSave(index)"
            title="Eliminar Fila"
            class="py-1 px-2 m-1 font-medium leading-5 text-white transition-colors duration-150 bg-red-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-trash" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            v-on:click="isHidden = true"
            v-if="!isHidden"
            @click="updateRowTransactionEdit(index)"
            class="px-1 py-1 text-sm font-medium leading-5 text-blue-500 hover:text-white transition-colors duration-150 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-edit" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full text-sm">
      <div
        class="flex md:flex-row flex-col items-end justify-end font-semibold tracking-wide text-left uppercase border rounded-b-md dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
      >
        <div class="relative items-end justify-end bg-green-50">
          <div class="px-4">Total Debito</div>
          <div class="px-4 text-right">
            {{ formatNumber(objAccount.total_debit, ",", "$", true) }}
          </div>
        </div>
        <div class="relative items-end justify-end bg-yellow-50">
          <div class="px-4">Total Credito</div>
          <div class="px-4 text-right">
            {{ formatNumber(objAccount.total_credit, ",", "$", true) }}
          </div>
        </div>
      </div>
    </div>

    <modal name="commentEntries">
      <div class="p-4 semibold text-center">
        Agregue un comentario relacionado a la
        <span class="text-red-700 font-bold"> eliminación </span> del asiento
      </div>
      <div class=" ">
        <textarea
          class="border-2 border-gray-300 rounded-md resize-none"
          v-model="comment"
          cols="50"
          rows="7"
        ></textarea>
      </div>
      <div class="text-sm w-full text-right p-3">
        <button
          v-if="comment != ''"
          @click="deleteAccountingEntryWhitComment()"
          class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple"
        >
          Guardar
        </button>
      </div>
    </modal>
    <modal name="commentEditEntries">
      <div class="p-4 semibold text-center">
        Agregue un comentario relacionado a la
        <span class="text-blue-700 font-bold"> modificación </span> del asiento
      </div>
      <div class=" ">
        <textarea
          class="border-2 border-gray-300 rounded-md resize-none"
          v-model="comment"
          cols="50"
          rows="7"
        ></textarea>
      </div>
      <div class="text-sm w-full text-right p-3">
        <button
          v-if="comment != ''"
          @click="modifyAccountingEntryWhitComment()"
          class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple"
        >
          Guardar
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Axios from "axios";

// import Swal from 'sweetalert2'
import { mapState } from "vuex";
export default {
  name: "NumberAccTransactions",
  components: {},
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      objAccount: [],
      comment: "",
      objAccountOld:[],
      reference_id: null,
      editEntry: false,
      isHidden: false,
      editMode: -1,
    };
  },
  computed: {
    ...mapState(["user", "access_token","year"]),
  },
  mounted() {
    this.getAccTransaction();
    console.log(this.$route.params.number);
  },

  methods: {
    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },
    DeleteAccountingEntry(id) {
      this.reference_id = id;
      // console.log(this.objAccount.account_transactions)
      this.$modal.show("commentEntries");
    },
    saveAccountingEntry(id) {
      this.reference_id = id;
      // console.log(this.objAccount.account_transactions)
      this.$modal.show("commentEditEntries");
    },
    updateRowTransactionEdit(index) {
      this.objAccountOld = {...this.objAccount}
      
      // console.log(this.objAccount.account_transactions[index].debit)
      this.editMode = index;
      // console.log(this.objTransaction.transactions[index])
    },
    updateRowTransactionSave(index, item) {
      let debit = parseFloat(this.objAccount.account_transactions[index].debit)
      let credit = parseFloat(this.objAccount.account_transactions[index].credit)

      console.log(credit);
      console.log(debit);

      function validarDosDecimales(amount) {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(amount);
      }

      console.log(validarDosDecimales(debit))
      console.log(validarDosDecimales(credit))

      if(validarDosDecimales(debit)==false || validarDosDecimales(credit)==false){
        this.editMode = index;
        this.isHidden = true;
        alert('Ingrese un monto valido, con un maximo de 2 decimales y en formato numerico.')
      }else{
        
        this.editMode = -1;
      }
    },

    delRowTransactionSave(index) {
      console.log(index);
      this.objAccount.account_transactions.splice(index, 1);
      this.editMode = -1;
    },
    async deleteAccountingEntryWhitComment() {
      const URL = `${this.URL}create-pending-approval-by-company`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        reference_id: this.reference_id,
        company_id: this.user.companyId,
        requested_by_name: this.user.fullName,
        requested_by_id: this.user.userId,
        action: "delete",
        options: JSON.stringify(this.objAccount.account_transactions),
        comment: this.comment,
        company: this.user.companyId,
        year: this.year,
      };
      // console.log(params)
      try {
        let rs = await Axios.post(URL, params, fetchConfig);
        // this.objAccount = rs.data.data
        console.log(rs);
        this.comment = "";
        this.reference_id = null;
        this.$modal.hide("commentEntries");
        alert(`Asiento enviado a pendientes por eliminar`);
        // console.log(rs)
      } catch (error) {
        Object.keys(error).forEach(function (key) {
          if (error[key].responseText) {
            let fff = JSON.parse(error[key].responseText);
            console.log(fff);
            alert(fff.message);
          }
        });
      }
    },
    async modifyAccountingEntryWhitComment() {      
      const URL = `${this.URL}create-pending-approval-by-company`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        reference_id: this.reference_id,
        company_id: this.user.companyId,
        requested_by_name: this.user.fullName,
        requested_by_id: this.user.userId,
        action: "modify",
        options: JSON.stringify(this.objAccount.account_transactions),
        comment: this.comment,
        company: this.user.companyId,
        year: this.year,
      };
      console.log('params.options')
      console.log(params.options)
      const DEBIT = this.objAccount.account_transactions
      .map((item) =>
        item.debit == "" ? (item.debit = 0) : parseFloat(item.debit)
      )
      .reduce((prev, curr) => prev + curr, 0);
     
      const CREDIT = this.objAccount.account_transactions
      .map((item) =>
        item.credit == "" ? (item.credit = 0) : parseFloat(item.credit)
      )
      .reduce((prev, curr) => prev + curr, 0);

      console.log('DEBIT');
      console.log(DEBIT);
      console.log('CREDIT');
      console.log(CREDIT);
      
    this.resultDescuadre = DEBIT.toFixed(2) - CREDIT.toFixed(2);
      console.log('this.resultDescuadre');
      console.log(this.resultDescuadre);
      if(this.resultDescuadre!=0){
        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',});
        alert(`Verificar descuadre, hay una diferencia de: ${formatter.format(Math.abs(this.resultDescuadre))}`);
      }else{
      try {
        let rs = await Axios.post(URL, params, fetchConfig);
        // this.objAccount = rs.data.data
        // console.log(rs.data.data)
        // return
        this.comment = "";
        this.reference_id = null;
        this.$modal.hide("commentEditEntries");
        alert(`Asiento enviado a pendientes por modificar`);
        console.log(rs);
      } catch (error) {
        Object.keys(error).forEach(function (key) {
          if (error[key].responseText) {
            let fff = JSON.parse(error[key].responseText);
            console.log(fff);
            alert(fff.message);
          }
        });
      }
  }
  },
    async getAccTransaction() {
      const URL = `${this.URL}showAccountingEntryDetails`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        accounting_entry_code: this.$route.params.number,
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
      };

      console.log(params);
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      this.objAccount = rsAccount.data.data;
      console.log(this.objAccount);
    },
    formatearFecha(fecha) {
      const fechaObj = new Date(fecha);
      const año = fechaObj.getFullYear();
      const mes = (fechaObj.getMonth() + 1).toString().padStart(2, "0"); // El mes se indexa desde 0
      const dia = fechaObj.getDate().toString().padStart(2, "0");
      return `${dia}-${mes}-${año}`;
    },
  },

  watch: {},
};
</script>

<style></style>
